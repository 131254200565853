import React from "react"
import { graphql } from "gatsby"
import { Context as ResponsiveContext } from "react-responsive"
import { css } from "@emotion/core"

// Components
import SEO from "../components/seo"
import { Layout } from "../components/Layout"
import BannerShape from "../components/BannerShape"

// Custom styles & Bootstrap
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import styles from "./career.module.scss"

const Career = props => {
  const {
    data: {
      wpcontent: { career },
    },
  } = props

  const { title, content, date } = career

  const d = new Date(date)
  const mm = d.toLocaleString("default", { month: "long" })
  const dd = d.getDate()
  const yyyy = d.getFullYear()

  return (
    <Layout>
      <SEO title={title} />
      <ResponsiveContext.Provider value={{ maxWidth: 575 }}>
        <BannerShape />
      </ResponsiveContext.Provider>
      <ResponsiveContext.Provider value={{ minWidth: 576 }}>
        <Container
          fluid
          css={css`
            padding: 0;
            @media (max-width: 575px) {
              display: none;
            }
          `}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="30 0 950 100"
            preserveAspectRatio="none"
            css={css`
              transform: rotateY(180deg);
            `}
            width="auto"
            height="12rem"
          >
            <path
              fill="#316d49"
              d="M0,0c0,0,0,6,0,6.7c0,18,240.2,93.6,615.2,92.6C989.8,98.5,1000,25,1000,6.7c0-0.7,0-6.7,0-6.7H0z"
            ></path>
          </svg>
        </Container>
      </ResponsiveContext.Provider>
      <Container className={styles.postContainer}>
        <Row className="justify-content-md-center">
          <Col sm="12" md="10" lg="8">
            <h1
              className={styles.title}
              dangerouslySetInnerHTML={{ __html: title }}
            />
            <div className={styles.detailContainer}>
              <p>{`${mm} ${dd}, ${yyyy}`}</p>
            </div>
            <div
              className="content-container"
              dangerouslySetInnerHTML={{ __html: content }}
            />
            <div className={styles.buttonContainer}>
              <a
                className={styles.link}
                href={`mailto:nice.dawili@synergyts.co.uk?subject=Application for ${career.title}`}
              >
                Apply Now
              </a>
            </div>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default Career

export const pageQuery = graphql`
  query GET_CAREER($id: ID!) {
    wpcontent {
      career(id: $id, idType: ID) {
        uri
        title(format: RENDERED)
        content(format: RENDERED)
        date
        acfCareerFields {
          averageProcessingTime
          salary
          workLocation
          workingHours
        }
      }
    }
  }
`
